var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-card', {
          attrs: {
            "id": "card-user-setting"
          }
        }, [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-0 position-relative"
        }, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.$refs.refEmployeeAvatar.$el.click();
            }
          }
        }, [_c('b-avatar', {
          attrs: {
            "size": "70",
            "src": _vm.employeeDataToEdit.avatar,
            "text": _vm.avatarText("".concat(_vm.employeeDataToEdit.firstName, " ").concat(_vm.employeeDataToEdit.lastName)),
            "variant": "light-success",
            "height": "70"
          }
        })], 1), _c('b-button', {
          staticClass: "position-absolute",
          staticStyle: {
            "padding": "4px",
            "top": "-10px",
            "right": "-10px"
          },
          attrs: {
            "variant": "light",
            "size": "sm",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clearEmployeeAvatar();
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon"
          }
        })], 1), _c('b-form-file', {
          ref: "refEmployeeAvatar",
          attrs: {
            "accept": ".jpg, .png",
            "hidden": true,
            "plain": ""
          },
          on: {
            "input": _vm.inputImageFormData
          },
          model: {
            value: _vm.employeeAvatar,
            callback: function callback($$v) {
              _vm.employeeAvatar = $$v;
            },
            expression: "employeeAvatar"
          }
        })], 1), _c('b-media-body', {
          staticClass: "mt-75 ml-75"
        }, [_c('b-card-text', {
          staticClass: "text-heading-5 mb-25"
        }, [_vm._v(" " + _vm._s(_vm.employeeDataToEdit.username.toUpperCase()) + " ")]), _c('b-card-text', [_vm._v(_vm._s(_vm.employeeDataToEdit.employeeCode))])], 1)], 1), _c('b-form', {
          staticClass: "mt-1"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.lastName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "last-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.lastName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "last-name",
                  "name": _vm.$t('myAccount.lastName'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.lastName')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeDataToEdit.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "lastName", $$v);
                  },
                  expression: "employeeDataToEdit.lastName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.firstName'),
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "first-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.firstName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "first-name",
                  "name": _vm.$t('myAccount.firstName'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.firstName')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeDataToEdit.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "firstName", $$v);
                  },
                  expression: "employeeDataToEdit.firstName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.email'),
            "rules": "required|email|max:50"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "email"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.email')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "name": _vm.$t('myAccount.email'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.email')),
                  "maxlength": "50",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300
                },
                model: {
                  value: _vm.employeeDataToEdit.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "emailAddress", $$v);
                  },
                  expression: "employeeDataToEdit.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.phone'),
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "phone-number"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.phone')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "phone-number",
                  "name": _vm.$t('myAccount.phone'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.phone')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300
                },
                model: {
                  value: _vm.employeeDataToEdit.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "phoneNumber", $$v);
                  },
                  expression: "employeeDataToEdit.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.birthday'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "birthday"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.birthday')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('flat-pickr', {
                staticClass: "form-control  px-50 py-0",
                style: {
                  height: '30px'
                },
                attrs: {
                  "id": "birthday",
                  "name": _vm.$t('flight.dob'),
                  "config": Object.assign({}, _vm.configFlatPickr, {
                    altInputClass: 'form-control input px-1 py-0'
                  }),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.birthday'))
                },
                model: {
                  value: _vm.employeeDataToEdit.birthDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "birthDay", $$v);
                  },
                  expression: "employeeDataToEdit.birthDay"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.gender'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "gender-type"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('employee.gender')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "label": "label",
                  "options": _vm.genderOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "gender-type"
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.employeeDataToEdit.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "gender", $$v);
                  },
                  expression: "employeeDataToEdit.gender"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.idNumber'),
            "rules": "max:12|min:9"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "id-number"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.idNumber')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "id-number",
                  "name": _vm.$t('myAccount.idNumber'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.idNumber')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "12",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeDataToEdit.identityCardNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "identityCardNumber", $$v);
                  },
                  expression: "employeeDataToEdit.identityCardNumber"
                }
              })], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.placeOfIssue'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "place-of-issue"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.placeOfIssue')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "place-of-issue",
                  "name": _vm.$t('myAccount.placeOfIssue'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.placeOfIssue'))
                },
                model: {
                  value: _vm.employeeDataToEdit.placeOfIssue,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "placeOfIssue", $$v);
                  },
                  expression: "employeeDataToEdit.placeOfIssue"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.issueDate'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "issue-date"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.issueDate')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "id": "issue-date",
                  "name": _vm.$t('myAccount.issueDate'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.issueDate')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "max": new Date(),
                  "min": new Date(1920, 0, 1),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.employeeDataToEdit.issuedDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "issuedDate", $$v);
                  },
                  expression: "employeeDataToEdit.issuedDate"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('myAccount.expDate'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "exp-date"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.expDate')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "id": "exp-date",
                  "name": _vm.$t('myAccount.expDate'),
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.expDate')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.employeeDataToEdit.expDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "expDate", $$v);
                  },
                  expression: "employeeDataToEdit.expDate"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('employee.bookerId'),
            "rules": "bookerId|min:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_vm.isBookerIdF1 ? _c('b-form-group', {
                attrs: {
                  "label-for": "booker-id"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('employee.bookerId')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "booker-id",
                  "name": _vm.$t('employee.bookerId'),
                  "lazy-formatter": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput
                },
                model: {
                  value: _vm.employeeDataToEdit.bookerId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.employeeDataToEdit, "bookerId", $$v);
                  },
                  expression: "employeeDataToEdit.bookerId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1) : _vm._e()];
            }
          }], null, true)
        })], 1), _c('b-col', {
          staticClass: "col-12-btn-button text-center",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-button', {
          staticClass: "mr-50",
          staticStyle: {
            "min-width": "120px"
          },
          attrs: {
            "variant": "outline-secondary",
            "pill": ""
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.back')) + " ")]), _c('b-button', {
          staticClass: "ml-50",
          staticStyle: {
            "min-width": "120px"
          },
          attrs: {
            "variant": "gradient",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.saveHandle);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.save')) + " ")])], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }