<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-card id="card-user-setting">
      <!-- ANCHOR media -->
      <b-media no-body>
        <!-- ANCHOR employee avatar -->
        <b-media-aside class="mr-0 position-relative">
          <b-link @click="$refs.refEmployeeAvatar.$el.click()">
            <b-avatar
              size="70"
              :src="employeeDataToEdit.avatar"
              :text="avatarText(`${employeeDataToEdit.firstName} ${employeeDataToEdit.lastName}`)"
              :variant="`light-success`"
              height="70"
            />
          </b-link>
          <b-button
            variant="light"
            size="sm"
            pill
            class="position-absolute"
            style="padding: 4px; top: -10px; right: -10px;"
            @click="clearEmployeeAvatar()"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-form-file
            ref="refEmployeeAvatar"
            v-model="employeeAvatar"
            accept=".jpg, .png"
            :hidden="true"
            plain
            @input="inputImageFormData"
          />
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-card-text class="text-heading-5 mb-25">
            {{ employeeDataToEdit.username.toUpperCase() }}
          </b-card-text>
          <b-card-text>{{ employeeDataToEdit.employeeCode }}</b-card-text>
        </b-media-body>
      </b-media>

      <!-- SECTION form -->
      <b-form class="mt-1">
        <b-row>
          <!-- ANCHOR lastName -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.lastName')"
              rules="required"
            >
              <b-form-group label-for="last-name">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.lastName') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="last-name"
                  v-model="employeeDataToEdit.lastName"
                  :name="$t('myAccount.lastName')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.lastName')}`"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimInput"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR firstName -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.firstName')"
              rules="required|max:30"
            >
              <b-form-group label-for="first-name">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.firstName') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="first-name"
                  v-model="employeeDataToEdit.firstName"
                  :name="$t('myAccount.firstName')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.firstName')}`"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimInput"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR email -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.email')"
              :rules="`required|email|max:50`"
            >
              <!-- Bỏ unique: |isUnique:employee,email,${employeeData.emailAddress} -->
              <b-form-group label-for="email">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.email') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="email"
                  v-model="employeeDataToEdit.emailAddress"
                  :name="$t('myAccount.email')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.email')}`"
                  maxlength="50"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimInput"
                  :debounce="300"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR phoneNumber -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.phone')"
              :rules="`required|phoneNumber`"
            >
              <!-- |isUnique:employee,phonenumber,${employeeData.phoneNumber} -->
              <b-form-group label-for="phone-number">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.phone') }}
                    <span class="text-danger">(*)</span>
                  </div>
                </template>
                <b-form-input
                  id="phone-number"
                  v-model="employeeDataToEdit.phoneNumber"
                  v-remove-non-numeric-chars.allNumber
                  :name="$t('myAccount.phone')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.phone')}`"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimInput"
                  :debounce="300"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR birthday -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.birthday')"
              rules=""
            >
              <b-form-group label-for="birthday">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.birthday') }}
                    <!-- <span class="text-danger">(*)</span> -->
                  </div>
                </template>
                <flat-pickr
                  id="birthday"
                  v-model="employeeDataToEdit.birthDay"
                  :name="$t('flight.dob')"
                  class="form-control  px-50 py-0"
                  :config="{
                    ...configFlatPickr,
                    altInputClass: 'form-control input px-1 py-0',
                  }"
                  :style="{height: '30px'}"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.birthday')}`"
                />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <!-- ANCHOR Gender -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.gender')"
              rules=""
            >
              <b-form-group label-for="gender-type">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('employee.gender') }}
                  </div>
                </template>
                <v-select
                  v-model="employeeDataToEdit.gender"
                  label="label"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="gender-type"
                >
                  <template #option="data">
                    <span>
                      {{ $t(data.label) }}
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ $t(data.label) }}
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- ANCHOR idNumber -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.idNumber')"
              rules="max:12|min:9"
            >
              <b-form-group label-for="id-number">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.idNumber') }}
                  </div>
                </template>
                <b-form-input
                  id="id-number"
                  v-model="employeeDataToEdit.identityCardNumber"
                  v-remove-non-numeric-chars.allNumber
                  :name="$t('myAccount.idNumber')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.idNumber')}`"
                  :state="getValidationState(validationContext) === false ? false : null"
                  maxlength="12"
                  lazy-formatter
                  :formatter="trimInput"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- ANCHOR place of issue -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.placeOfIssue')"
              rules=""
            >
              <b-form-group label-for="place-of-issue">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.placeOfIssue') }}
                  </div>
                </template>
                <b-form-input
                  id="place-of-issue"
                  v-model="employeeDataToEdit.placeOfIssue"
                  :name="$t('myAccount.placeOfIssue')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.placeOfIssue')}`"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- ANCHOR issue date -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.issueDate')"
              rules=""
            >
              <b-form-group label-for="issue-date">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.issueDate') }}
                  </div>
                </template>
                <b-form-datepicker
                  id="issue-date"
                  v-model="employeeDataToEdit.issuedDate"
                  :name="$t('myAccount.issueDate')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.issueDate')}`"
                  class="form-control"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :max="new Date()"
                  :min="new Date(1920, 0, 1)"
                  show-decade-nav
                  hide-header
                  locale="vi"
                  boundary="window"
                  no-flip
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- ANCHOR expire date -->
          <b-col
            cols="12"
            sm="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('myAccount.expDate')"
              rules=""
            >
              <b-form-group label-for="exp-date">
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('myAccount.expDate') }}
                  </div>
                </template>
                <b-form-datepicker
                  id="exp-date"
                  v-model="employeeDataToEdit.expDate"
                  :name="$t('myAccount.expDate')"
                  :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.expDate')}`"
                  class="form-control"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  :min="new Date()"
                  show-decade-nav
                  hide-header
                  locale="vi"
                  boundary="window"
                  no-flip
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('employee.bookerId')"
              rules="bookerId|min:5"
            >
              <b-form-group
                v-if="isBookerIdF1"
                label-for="booker-id"
              >
                <template #label>
                  <div class="text-heading-5">
                    {{ $t('employee.bookerId') }}
                  </div>
                </template>
                <b-form-input
                  id="booker-id"
                  v-model="employeeDataToEdit.bookerId"
                  :name="$t('employee.bookerId')"
                  lazy-formatter
                  :state="getValidationState(validationContext) === false ? false : null"
                  :formatter="trimInput"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR actions -->
          <b-col
            sm="12"
            class="col-12-btn-button text-center"
          >
            <b-button
              variant="outline-secondary"
              pill
              class="mr-50"
              style="min-width: 120px;"
              @click="backHandle"
            >
              {{ $t('myAccount.back') }}
            </b-button>
            <b-button
              variant="gradient"
              pill
              class="ml-50"
              style="min-width: 120px;"
              @click="handleSubmit(saveHandle)"
            >
              {{ $t('myAccount.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    <!-- !SECTION -->
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BAvatar,
  BFormInput, BRow, BCol, BFormInvalidFeedback,
  BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BFormDatepicker,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { apiEmployee } from '@/api'
import { genderOptions } from '@/constants/selectOptions'
import store from '@/store'
import VueI18n from '@/libs/i18n'

import { getDifference } from '@core/utils/utils'
import {
  // useInputImageRenderer,
  useInputImageFormData,
} from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BFormDatepicker,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  props: {
    // userData: {
    //   type: Object,
    //   default: () => {},
    // },
    employeeData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { toastSuccess, toastError } = useToast()
    const employeeDataToEdit = ref(JSON.parse(JSON.stringify(props.employeeData)))

    const refEmployeeAvatar = ref(null)
    const previewEl = ref(null)

    const employeeAvatar = ref(null)

    const { inputImageFormData } = useInputImageFormData(refEmployeeAvatar, data => {
      employeeDataToEdit.value.avatar = data.url
    })

    const clearEmployeeAvatar = () => {
      employeeDataToEdit.value.avatar = null
    }

    function resetEmployeeToEdit() {
      employeeDataToEdit.value = JSON.parse(JSON.stringify(this.employeeData))
    }

    const { refFormObserver, getValidationState } = formValidation(resetEmployeeToEdit)
    function backHandle() {
      this.$router.go(-1)
    }

    const isBookerIdF1 = props.employeeData.agency.id === 1000000 && props.employeeData.type === 'BE'
    const isBookerIdF2 = props.employeeData.agency.parentAgency?.id === 1000000 && props.employeeData.type === 'ADM'

    async function saveHandle() {
      this.$bvModal.show('modal-api-loading')

      const dataToUpdate = getDifference(employeeDataToEdit.value, props.employeeData)
      delete dataToUpdate.bookerId
      if (dataToUpdate.birthDay === '') delete dataToUpdate.birthDay
      apiEmployee.updateEmployee(employeeDataToEdit.value.id, dataToUpdate)
        .then(async res => {
          if (
            isBookerIdF1
            && (employeeDataToEdit.value.bookerId !== props.employeeData.bookerId)
            && [employeeDataToEdit.value.bookerId, props.employeeData.bookerId].some(it => !!it)
          ) {
            await apiEmployee.updateBookerId(props.employeeData.id, {
              bookerId: employeeDataToEdit.value.bookerId || '',
            })
          }
          toastSuccess({
            title: 'messagesList.success',
            content: `Cập nhật ${res.lastName} ${res.firstName} thành công`,
          })
        })
        .then(async () => {
          await store.dispatch('userStore/fetchMeData')
        })
        .catch(error => {
          console.error(error)
          toastError({
            title: 'messagesList.error',
            content: `Lỗi cập nhật: ${error.message || error}}`,
          })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }
    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    return {
      configFlatPickr,
      employeeDataToEdit,
      refEmployeeAvatar,
      previewEl,
      employeeAvatar,
      avatarText,
      isBookerIdF1,
      isBookerIdF2,
      inputImageFormData,
      clearEmployeeAvatar,
      backHandle,
      saveHandle,
      refFormObserver,
      getValidationState,
      trimInput,
      genderOptions,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
